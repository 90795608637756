<template>
  <div class="projectInfo">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide"><img :src="$store.state.pImg" alt="" /></div>
        <!-- 如果需要分页器 -->
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <div class="flex" style="margin: 20px 0">
      <span class="infoBtn">成员数量：{{data.peoplesum}}个</span>
      <span class="infoBtn">模型数量：{{data.modelsum}}个</span>
    </div>
    <div class="flex">
      <div style="width:49%">
        <div
          class="flex"
          style="margin-bottom: 5px;justify-content:flex-start"
          v-for="(item,index) in listLeft"
          :key="index"
        >
          <div>{{item.name}}：</div>
          <div class="nowrap" style="width:50%">{{item.value}}</div>
        </div>
      </div>
      <div style="width:49%">
        <div
          class="flex"
          style="margin-bottom: 5px;justify-content:flex-start"
          v-for="(item,index) in listRight"
          :key="index"
        >
          <div>{{item.name}}：</div>
          <div class="nowrap" style="width:50%">{{item.value}}</div>
        </div>
      </div>
    </div>
    <!-- <van-button
      type="info"
      @click="$router.go(-1)"
      style="width: 100%; position: fixed; bottom: 0; left: 0"
      square
      size="small"
      >返回</van-button
    > -->
  </div>
</template>

<script>
export default {
  name: "projectInfo",
  components: {},
  data() {
    return {
      data: {

      },
      listLeft:[
        {
          name:"项目编号",
          value:""
        },
        {
          name:"建筑类别",
          value:""
        },
        {
          name:"总体投资",
          value:""
        },
        {
          name:"项目面积",
          value:""
        },
        {
          name:"总体工期",
          value:""
        },
        {
          name:"咨询合同",
          value:""
        },
        {
          name:"BIM耗时",
          value:""
        },
        {
          name:"服务阶段",
          value:""
        },
        {
          name:"数据容量",
          value:""
        },
        {
          name:"项目经理",
          value:""
        },
      ],
      listRight:[
        {
          name:"项目简称",
          value:""
        },
        {
          name:"开工日期",
          value:""
        },
        {
          name:"计划竣工",
          value:""
        },
        {
          name:"项目地点",
          value:""
        },
        {
          name:"建设单位",
          value:""
        },
        {
          name:"设计单位",
          value:""
        },
        {
          name:"施工单位",
          value:""
        },
        {
          name:"监理单位",
          value:""
        },
        {
          name:"创建日期",
          value:""
        },
        {
          name:"成员数量",
          value:""
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    async updateData() {
      let res = await this.$store.dispatch("GetProjectInfos");
      this.$emit("updateLoading", "项目信息");
      this.data = res.Data;
      this.listLeft[0].value=res.Data.proj02;
      this.listLeft[1].value=res.Data.proj10;
      this.listLeft[2].value=res.Data.proj19;
      this.listLeft[3].value=res.Data.proj12;
      this.listLeft[4].value=res.Data.proj14;
      this.listLeft[5].value=res.Data.proj08;
      this.listLeft[6].value=res.Data.proj09;
      this.listLeft[7].value=res.Data.proj11;
      this.listLeft[8].value=res.Data.proj04;
      this.listLeft[9].value=res.Data.admin;
      this.listRight[0].value=res.Data.proj07;
      this.listRight[1].value=res.Data.proj20;
      this.listRight[2].value=res.Data.proj21;
      this.listRight[3].value=res.Data.proj13;
      this.listRight[4].value=res.Data.proj15;
      this.listRight[5].value=res.Data.proj16;
      this.listRight[6].value=res.Data.proj18;
      this.listRight[7].value=res.Data.proj18;
      this.listRight[8].value=res.Data.proj21;
      this.listRight[9].value=res.Data.peoplesum;
    },
  },
  created() {
    this.updateData();
  },
  mounted() {
    new Swiper(".swiper-container", {
      loop: true,
      pagination: {
        // el: ".swiper-pagination",
      },
      spaceBetween: 20,
      // autoplay: {
      //   delay: 3000, //时间 毫秒
      //   disableOnInteraction: false, //用户操作之后是否停止自动轮播默认true
      // },
    });
  },
};
</script>
<style lang='scss' scoped>
.projectInfo {
  min-height: 83vh;
  height: auto;
  overflow: hidden;
  padding: 0 40px;
  .swiper-container {
    border-radius: 10px;
  }
  .swiper-slide {
    height: 340px;
    background: rgb(228, 228, 228);
    border-radius: 10px;
  }
  .infoBtn {
    height: 60px;
    border-radius: 10px;
    padding: 10px 20px;
    box-shadow: 0 0 7px 3px rgba(102, 143, 255, 0.397);
    text-align: center;
    color: white;
    background-image: linear-gradient(
      to top,
      rgb(69, 143, 253) 30%,
      rgb(77, 192, 245)
    );
  }
  img{
    width: 100%;
    height: 100%;
  }
}
</style>